<template>
  <main class="Financial">
    <TheSubHeader
      icon="money"
      title="Tipo de Veículo"
      page-title-tag="Financial Edit Vehicles"
      type="filter"
      organizer-columns="600px"
    >
      <template #filter>
        <section
          class="Financial__subheaderTab"
        >     
          <!-- Plates filter -->
          <BaseTreeSelect
            v-model="filters.plates"
            name="Placa"
            label="Placa"
            placeholder="Pesquisar"
            multiple
            suggestions="searchVehiclePlates"
            @input="updateTags(), fetchVehiclesEdit()"
          />

          <!-- Types filter -->
          <BaseTreeSelect
            v-model="filters.type"
            name="Tipo"
            label="Tipo"
            multiple
            placeholder="Selecione o tipo de veículo"
            :select-options="vehiclesTypes"
            @input="updateTags(), fetchVehiclesEdit()"
          />
        </section>
      </template>

      <!-- filters tag -->
      <template v-if="allTags.length > 0" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>

    <section class="Financial__content">
      <DataTable
        :header="vehiclesEdit.data.headers"
        :table="vehiclesEdit.data.content"
        :max-size="false"
        @select="setStatusValue"
      />

      <!-- confirm status button -->
      <div class="action-button-container">
        <BaseButton
          label="Salvar"
          color="primary-base"
          class="button"
          filled
          text-color="white"
          :disabled="!hasValue"
          @click="saveVehiclesEdition"
        />   
      </div>
      

      <Pagination @page="fetchVehiclesEdit" @paginate="fetchVehiclesEdit" />
    </section>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { TheSubHeader } from '@/components/organisms'
import { BaseButton, BaseTreeSelect } from '@/components/atoms'
import { Pagination, DataTable, FiltersTag } from '@/components/molecules'
import { financial } from '@/api'

export default {
  name: 'EditVehicles',
  components: {
    TheSubHeader,
    BaseButton,
    BaseTreeSelect,
    DataTable,
    Pagination,
    FiltersTag
  },

  data() {
    return {
      value: [],
      filters: {
        plates: [],
        type: []
      },
      allTags: []
    }
  },
  computed: {
    ...mapState({
      paginate: state => state.pagination.paginate,
      page: state => state.pagination.page,
      vehiclesEdit: state => state.financial.vehiclesEdit,
      vehiclesTypes: state => state.financial.vehiclesTypes
    }),

    hasValue() {
      return !!this.value.length
    }
  },

  mounted() {
    this.fetchVehiclesEdit()
    this.fetchVehiclesTypes()
  },

  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
      fetchVehiclesEditHistory: 'financial/fetchVehiclesEdit',
      fetchVehiclesTypes: 'financial/fetchVehiclesTypes'
    }),

    updateTags(){
      this.allTags = [...this.filters.plates, ...this.filters.type]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.type = this.filters.type.filter((tag) => tag.name !== name)
      this.filters.plates = this.filters.plates.filter((tag) => tag.name !== name)
      this.fetchVehiclesEdit()
    },

    clearAllTags(){
      this.allTags = []
      this.filters.plates = []
      this.filters.type = []
      this.fetchVehiclesEdit()
    },

    setStatusValue(data) {
      if(!data.inputs.find(el => el.optionSelected)) {
        this.$nextTick(() => {
          this.value = this.value.filter((v) => v.id !== data.id)
        })
      }

      this.value = this.value.filter((v) => v.id !== data.id)
      this.value.push(data)
    },

    async saveVehiclesEdition() {
      this.setLoading(true)

      const items = []

      for (let i in this.value) {
        const a = {
          vehicle_id: this.value[i].id,
          type_id: this.formatInputStatus(this.value[i].inputs)
        }
        items.push(a)
      }

      const body = {
        vehicles: items
      }

      await financial.submitVehiclesEdit(body, (res) => {
        this.$toast.success(res.message, { position: 'bottom-center' })
        this.fetchVehiclesEdit()
      }, (e) => e)
      this.setLoading(false)
    },

    formatInputStatus(status) {
      for (let i in status ) {
        return status[i].optionSelected.id
      }
    },

    async fetchVehiclesEdit() {
      this.clearPagination(20)
      
      let payload = {
        page: this.page,
        paginate: this.paginate,
        filters: {
          type_id: this.filters.type,
          id: this.filters.plates
        }
      }

      await this.fetchVehiclesEditHistory(payload, (r) => r, (e) => e, () => this.setLoading(false))
      this.setPagination(this.vehiclesEdit)
    },

    handleOpenModal(type, text) {
      const data = {
        type: type,
        text: text
      }
      this.$refs.confirmModal.open(data)
    },
  }
}
</script>

<style lang="scss" scoped>
.Financial {
  &__subheaderTab {
    display: flex;
    align-items: flex-end;
    grid-gap: 1rem;
      @media #{$mobile-view} {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 1180px;
    width: 100%;
    max-width: 1180px;
    padding: 0 0 3rem 0;
    @media (max-width: $viewport-lg) {
      padding: 0 1rem 3rem 1rem;
    }

    /deep/ .DataTableSelect {
      width: 300px;
    }

    /deep/ .DataTableSelect__Content {
      width: 100%;
      justify-content: flex-end;

      .BaseMultiselect {
        width: 300px;
        padding: 5px;
      }
    }

    .action-button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
        button {
          width: 150px;
          margin: 5px;
          height: 30px;
        }
    }
  }
}
</style>